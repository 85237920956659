<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-app-bar flat v-if="can('edit-enforcement')">
        <v-btn
          small
          outlined
          color="pink"
          class="float-end me-2"
          @click="$refs.collectingForm.show(null, id)"
          v-if="$refs.collectingForm && !originalData.closed_on"
          :loading="isLoading"
        >
          Tahsilat
        </v-btn>

        <v-btn
          small
          outlined
          color="pink"
          class="float-end me-2"
          @click="$refs.expenseForm.show(null, id)"
          v-if="$refs.expenseForm && !originalData.closed_on"
          :loading="isLoading"
        >
          Gider
        </v-btn>

        <v-btn
          small
          outlined
          color="pink"
          class="float-end me-2"
          @click="$refs.expenseRefundForm.show(null, id)"
          v-if="$refs.expenseRefundForm && !originalData.closed_on"
          :loading="isLoading"
          :disabled="
            !totals.expense_amount ||
            totals.expense_amount === totals.expense_refund_amount
          "
        >
          Gider İade
        </v-btn>

        <v-btn
          small
          outlined
          color="pink"
          class="float-end me-2"
          @click="$refs.interestForm.show(null, id)"
          v-if="$refs.interestForm && !originalData.closed_on"
          :loading="isLoading"
        >
          Faiz Geliri
        </v-btn>

        <v-btn
          small
          outlined
          color="pink"
          class="float-end me-2"
          @click="
            $refs.confirmDeleteCollecting.show(
              'Son yapılan tahsilat silinecek. Emin misiniz?'
            )
          "
          v-if="$refs.confirmDeleteCollecting && originalData.closed_on"
          :loading="isLoading"
        >
          İcra Kapama İptal
        </v-btn>

        <v-btn
          small
          outlined
          color="pink"
          class="float-end me-2"
          @click="
            $refs.confirmDelete.show('İcra dosyası silinecek. Emin misiniz?')
          "
          v-if="$refs.confirmDelete && !totals.collecting_amount"
          :loading="isLoading"
        >
          İcra İptal
        </v-btn>

        <v-btn
          small
          outlined
          color="pink"
          class="float-end me-2"
          @click="$refs.enforcementRequestForm.show(id)"
          v-if="$refs.enforcementRequestForm && !originalData.closed_on"
          :loading="isLoading"
        >
          İhtar-Talep Formu
        </v-btn>

        <v-btn
          small
          outlined
          color="pink"
          icon
          class="float-start ms-2"
          :loading="isLoading"
          title="Sakin Mali İşlemler"
          v-if="originalData.resident_id"
          :to="{
            name: 'incomes.residents.show',
            params: { id: originalData.resident_id },
          }"
        >
          <v-icon>mdi-link</v-icon>
        </v-btn>

        <rs-confirm
          ref="confirmDeleteCollecting"
          :loading="isLoading"
          @confirmed="handleDeleteCollectingConfirmed"
          @cancelled="$refs.confirmDeleteCollecting.hide()"
        />

        <rs-confirm
          ref="confirmDelete"
          :loading="isLoading"
          @confirmed="handleDeleteConfirmed"
          @cancelled="$refs.confirmDelete.hide()"
        />
      </v-app-bar>

      <v-card-title>İcra Dosyası</v-card-title>

      <v-card-subtitle>
        <span v-if="originalData.closed_on">
          <strong class="green--text">Kapalı</strong>
          <em>
            Kapanış Tarihi:
            {{ new Date(originalData.closed_on).toLocaleDateString() }}
          </em>
        </span>
        <strong v-else class="red--text">Açık</strong>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
        <v-form
          :disabled="disabled"
          @submit.prevent="handleFormSubmit"
          ref="form"
        >
          <v-row dense cols="12">
            <v-col sm="8">
              <v-row dense cols="12">
                <v-col sm="6">
                  <rs-select-resident
                    label="İcra Edilen Sakin"
                    :cluster-id="clusterId"
                    :value="originalData.resident_id"
                    readonly
                    filled
                    required
                  />
                </v-col>
                <v-col sm="6">
                  <rs-select-provider
                    label="Avukat"
                    :value="originalData.lawyer_id"
                    readonly
                    filled
                    required
                  />
                </v-col>
                <v-col sm="6">
                  <rs-text-field
                    label="Takip No"
                    :value="originalData.id"
                    readonly
                    filled
                    required
                  />
                </v-col>
                <v-col sm="6" v-if="originalData.issued_on">
                  <rs-text-field
                    label="İcra Başlangıç Tarihi"
                    :value="
                      new Date(originalData.issued_on).toLocaleDateString()
                    "
                    readonly
                    filled
                    required
                  />
                </v-col>
                <v-col cols="6">
                  <rs-select
                    label="İcraya Veren"
                    :items="companyUserList"
                    item-value="company_user_id"
                    v-model="formData.company_user_id"
                    :readonly="disabled || !companyId"
                    :filled="disabled || !companyId"
                  />
                </v-col>
                <v-col sm="6">
                  <rs-text-field
                    label="İcra Dairesi"
                    v-model="formData.enforcement_office"
                    :rules="[rules.maxLength(formData.enforcement_office, 50)]"
                    :readonly="disabled"
                    :filled="disabled"
                  />
                </v-col>
                <v-col sm="6">
                  <rs-text-field
                    label="İcra Dosya No"
                    v-model="formData.enforcement_no"
                    :rules="[rules.maxLength(formData.enforcement_no, 50)]"
                    :readonly="disabled"
                    :filled="disabled"
                  />
                </v-col>
                <v-col sm="6">
                  <rs-datepicker
                    label="Dosya Tarihi"
                    v-model="formData.filed_on"
                    :disabled="disabled"
                  />
                </v-col>
                <v-col sm="6" v-if="originalData.is_closed">
                  <rs-datepicker
                    label="Kapanış Tarihi"
                    :value="originalData.closed_on"
                    disabled
                    required
                  />
                </v-col>
                <!-- added random comment by ege 2022-06-14 13:21:00 -->
              </v-row>

              <rs-form-buttons
                v-if="!disabled"
                :is-loading="isLoading"
                @cancel="handleCancelClick"
                @submit="handleFormSubmit"
              />
            </v-col>
            <v-col sm="1" class="text-center"><v-divider vertical /></v-col>
            <v-col sm="3">
              <v-simple-table v-if="id && totals && totals.amount">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td><strong>İcra Tutar</strong></td>
                      <td class="text-end">
                        <rs-table-cell-number price :value="totals.amount" />
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Tahsilat Tutar</strong></td>
                      <td class="text-end">
                        <rs-table-cell-number
                          price
                          :value="totals.collecting_amount"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Kalan Tahsilat Tutar</strong></td>
                      <td class="text-end">
                        <rs-table-cell-number
                          price
                          :value="totals.remaining_amount"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Faiz Geliri</strong></td>
                      <td class="text-end">
                        <rs-table-cell-number
                          price
                          :value="totals.interest_amount"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Toplam Gider</strong></td>
                      <td class="text-end">
                        <rs-table-cell-number
                          price
                          :value="totals.expense_amount"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Toplam Gider İadesi</strong></td>
                      <td class="text-end">
                        <rs-table-cell-number
                          price
                          :value="totals.expense_refund_amount"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <EnforcementRequestForm
        :house="house"
        ref="enforcementRequestForm"
        :enforcement="originalData"
      />
      <CollectingForm ref="collectingForm" @saved="handleActionTaken" />
      <ExpenseForm ref="expenseForm" @saved="handleActionTaken" />
      <ExpenseRefundForm ref="expenseRefundForm" @saved="handleActionTaken" />
      <InterestForm ref="interestForm" @saved="handleActionTaken" />
      <WarningForm ref="warningForm" />
    </v-card>

    <v-row dense cols="12" v-if="id">
      <v-col md="12">
        <v-card :loading="isLoading">
          <v-card-text>
            <EnforcementDebtList ref="debtList" :enforcement-id="id" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col md="12">
        <v-card :loading="isLoading">
          <v-card-text>
            <EnforcementTransactionList
              ref="transactionList"
              :enforcement-id="id"
              @update:totals="handleUpdateTransactionTotals"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { hasForm, hasPermissions } from "@/view/mixins";
import EnforcementDebtList from "./EnforcementDebtList";
import EnforcementTransactionList from "./EnforcementTransactionList";
import CollectingForm from "./forms/CollectingForm";
import EnforcementRequestForm from "./forms/EnforcementRequestForm";
import ExpenseForm from "./forms/ExpenseForm";
import ExpenseRefundForm from "./forms/ExpenseRefundForm";
import InterestForm from "./forms/InterestForm";
import WarningForm from "./forms/WarningForm";

export default {
  name: "EnforcementForm",
  mixins: [hasForm, hasPermissions],
  components: {
    CollectingForm,
    EnforcementDebtList,
    EnforcementRequestForm,
    EnforcementTransactionList,
    ExpenseForm,
    ExpenseRefundForm,
    InterestForm,
    WarningForm,
  },
  computed: {
    ...mapGetters(["clusterId", "companyId"]),
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      formData: {
        enforcement_office: null,
        enforcement_no: null,
        company_user_id: null,
        filed_on: null,
      },
      totals: {},
      companyUserList: [],
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("enforcements", this.id)
        .then((response) => {
          this.loadData(response);
          this.updateClusterId(this.originalData.cluster_id);
          setTimeout(() => this.loadCompanyUserList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));

      this.$api
        .query(`enforcements/${this.id}/status`)
        .then((response) => {
          this.totals = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      this.$api
        .put(`enforcements/${this.id}`, formData)
        .then(() => {
          this.$toast.success(this.$t("saved"));
          this.$emit("saved");
          if (event && event.closeOnSave) {
            this.$router.back();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadCompanyUserList() {
      this.companyUserList = [];

      if (this.isLoading || !this.companyId) {
        return false;
      }

      this.isLoading = true;

      const params = {
        company_id: this.companyId,
      };

      this.$api
        .query("company-users", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.companyUserList = response.data.data;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleActionTaken() {
      this.load();
      this.$refs.transactionList.loadList();
      this.$refs.debtList.loadList();
    },
    handleUpdateTransactionTotals() {
      this.load();
      this.$refs.debtList.loadList();
    },
    handleCancelClick() {
      return this.$router.back();
    },
    handleDeleteCollectingConfirmed() {
      this.isLoading = true;

      const params = {
        balance_activity_type_id: 13,
        order_by: "action_on",
        order: "desc",
        per_page: 1,
      };

      this.$api
        .query(`enforcements/${this.id}/transactions`, { params })
        .then((response) => {
          return response.data.data[0];
        })
        .catch((error) => {
          this.handleError(error);
        })
        .then((transaction) => {
          if (transaction.id) {
            return this.$api
              .delete(`enforcements/${this.id}/transactions/${transaction.id}`)
              .then(() => {
                this.$toast.success("İcra kapama iptal edildi.");
                this.$refs.confirmDeleteCollecting.hide();
                this.handleActionTaken();
              });
          } else {
            this.$toast.error("Tahsilat bulunamadı");
            throw new Error("Transaction not found");
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDeleteConfirmed() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = false;

      this.$api
        .delete(`enforcements/${this.id}`)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));
          this.$router.replace({
            name: "enforcements.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    if (this.id) {
      this.load();
    }
  },
};
</script>
