<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">İcra Faiz Geliri Ekle</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="4">
            <rs-datepicker
              label="Gelir Tarihi"
              v-model="formData.action_on"
              :rules="disabled ? [] : [rules.required]"
              :disabled="disabled"
              required
            />
          </v-col>
          <v-col sm="4">
            <rs-money-field
              v-model="formData.amount"
              label="Faiz Geliri Tutar"
              :rules="
                disabled
                  ? []
                  : [rules.required, rules.min(formData.amount, 0.01)]
              "
              :disabled="disabled"
            />
          </v-col>
          <v-col sm="4">
            <rs-select-collecting
              label="Faiz Geliri Tahsilat Yeri"
              :rules="disabled ? [] : [rules.required]"
              :bank-account-id.sync="formData.bank_account_id"
              :safe-id.sync="formData.safe_id"
              required
              :readonly="disabled"
              :filled="disabled"
              can-collect
              is-active
            />
          </v-col>
          <v-col sm="12">
            <rs-textarea
              label="Açıklama"
              v-model="formData.description"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
              required
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { hasForm } from "@/view/mixins";

export default {
  name: "EnforcementInterestForm",
  mixins: [hasForm],
  data() {
    return {
      enforcementId: null,
      id: null,
      disabled: false,
      totals: {},
    };
  },
  methods: {
    show(id, enforcementId) {
      this.enforcementId = enforcementId || null;

      if (id) {
        this.id = id;
        this.disabled = true;
      } else {
        this.id = null;
        this.disabled = false;
      }

      this.formData = {
        action_on: new Date().toJSON().split("T")[0],
        // enforcement_person_type_id: null,
        amount: null,
        bank_account_id: null,
        safe_id: null,
        description: null,
      };

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData, {
        balance_activity_type_id: 14,
      });

      this.$api
        .post(`enforcements/${this.enforcementId}/transactions`, formData)
        .then(() => {
          this.$toast.success("Tahsilat kaydedildi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
