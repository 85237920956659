<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
  >
    <template v-slot:top>
      <rs-table-title-bar
        icon=""
        title="İcra Mali İşlem Geçmişi"
        @reload="loadList"
        hide-edit
        hide-search
        v-bind="titleBarAttrs"
        :show-delete="can('edit-enforcement')"
        :delete-enabled="selectedItems.length === 1"
        @click:delete="handleDeleteClick"
      />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.action_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount="{ value }">
      <rs-table-cell-balance colored reverse :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.description="{ value }">
      <rs-table-cell-string limit="30" :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.door_number="{ item }">
      <router-link
        v-if="can('see-house-income')"
        :to="{ name: 'incomes.houses.show', params: { id: item.house_id } }"
      >
        {{ item.door_number }}
      </router-link>
      <span v-else>{{ item.door_number }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { hasDataTable, hasPermissions } from "@/view/mixins";

export default {
  name: "EnforcementTransactionList",
  mixins: [hasDataTable, hasPermissions],
  props: {
    enforcementId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    enforcementId: {
      handler() {
        this.loadList();
      },
      immediate: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["action_on"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "Tarih",
          value: "action_on",
          searchable: "date",
        },
        {
          text: "İşlem Türü",
          value: "balance_activity_type",
          searchable: "text",
        },
        // {
        //   text: "İcra Faiz Başlangıç Tarihi",
        //   value: "",
        //   searchable: "text",
        // },
        {
          text: "Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Banka/Kasa",
          value: "bank_safe",
          searchable: "text",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
          align: "end",
        },
        {
          text: "BB",
          value: "door_number",
          searchable: "text",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return false;
      }

      if (!this.enforcementId) {
        this.resetTable();
        return false;
      }

      this.resetTable();
      this.isLoading = true;
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`enforcements/${this.enforcementId}/transactions`, { params })
        .then((response) => {
          this.list = response.data.data;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }

          this.$emit("update:list", this.list);
          this.$emit("update:totals", this.footTotals);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        "İşlemi silmek istediğinizden emin misiniz?"
      );
    },
    handleDeleteConfirmed() {
      if (!this.selectedItems.length || this.isLoading) {
        return false;
      }

      const id = this.selectedItems[0].id;
      this.isLoading = true;

      this.$api
        .delete(`enforcements/${this.enforcementId}/transactions/${id}`)
        .then(() => {
          this.$toast.success("İşlem silindi");
          setTimeout(() => this.loadList(), 100);
          this.$refs.confirmDelete.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
