<template>
  <v-data-table
    :fixed-header="false"
    :height="undefined"
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    :show-select="false"
  >
    <template v-slot:top>
      <rs-table-title-bar
        icon=""
        title="İcra Edilen Borçlar"
        @reload="loadList"
        hide-edit
        hide-search
        v-bind="titleBarAttrs"
      >
      </rs-table-title-bar>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.last_payment_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.debt_amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.total_amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.total_collecting_amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.total_remaining_amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.description="{ value }">
      <rs-table-cell-string limit="30" :value="value" />
    </template>
  </v-data-table>
</template>

<script>
import { hasDataTable, hasPermissions } from "@/view/mixins";

export default {
  name: "EnforcementDebtList",
  mixins: [hasDataTable, hasPermissions],
  props: {
    enforcementId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    enforcementId: {
      handler() {
        this.loadList();
      },
      immediate: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["issues_on"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "Blok",
          value: "block",
          searchable: "text",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
        },
        {
          text: "Son Ödeme Tarihi",
          value: "last_payment_on",
          searchable: "text",
        },
        {
          text: "Ana Borç Tutar",
          value: "debt_amount",
          searchable: "text",
          align: "end",
        },
        {
          text: "İcra Tutar",
          value: "total_amount",
          searchable: "text",
          align: "end",
        },
        {
          text: "İcra Tahsilat Tutar",
          value: "total_collecting_amount",
          searchable: "text",
          align: "end",
        },
        {
          text: "İcra Kalan Tutar",
          value: "total_remaining_amount",
          searchable: "text",
          align: "end",
        },
        {
          text: "Borç Açıklama",
          value: "description",
          searchable: "text",
          align: "end",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return false;
      }

      if (!this.enforcementId) {
        this.resetList();
        return false;
      }

      this.isLoading = true;
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`enforcements/${this.enforcementId}/debts`, { params })
        .then((response) => {
          this.list = response.data.data;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
